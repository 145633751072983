<script>
import Profile from "../chat/tabs/profile";
import layoutProfile from "@/views/layouts/layoutProfile";

export default {
	name: "Index",
	components: {
		Profile,
		layoutProfile,
	},
}
</script>

<template>
	<layoutProfile>
		<Profile/>
	</layoutProfile>
</template>