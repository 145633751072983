<script>
import {authMethods, profileComputed} from "@/state/helpers";

export default {
	name: "NavBarLeft",
	data() {
		return {
			userTheme: "light-theme",
		}
	},
	computed: {
		...profileComputed,
		isUserAdmin() {
			return Boolean(this.userInfo.is_admin);
		},
		userImage() {
			return this.userInfo.image;
		},
		currentRouteName() {
			return this.$route.name;
		}
	},
	methods: {
		...authMethods,
		setTheme(theme) {
			localStorage.setItem("user-theme", theme);
			this.userTheme = theme;
			document.documentElement.className = theme;
		},
		toggleTheme() {
			const activeTheme = localStorage.getItem("user-theme");
			if (activeTheme === "light-theme") {
				this.setTheme("dark-theme");
			} else {
				this.setTheme("light-theme");
			}
		},
		tryToLogOut() {
			this.logOut()
		},
	},
	mounted() {
		this.$store.dispatch("profile/getUserInfo");
	}
}
</script>

<template>
	<div
		class="side-menu flex-lg-column mr-lg-1"
		:style="currentRouteName === 'admin' ? 'position: fixed' : 'position: relative'"
	>
		<!-- LOGO -->
		<div class="navbar-brand-box">
			<router-link to="/" tag="a" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo.svg" alt height="30"/>
            </span>
			</router-link>

			<router-link tag="a" to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo.svg" alt height="30"/>
            </span>
			</router-link>
		</div>
		<!-- end navbar-brand-box -->

		<!-- Start side-menu nav -->
		<div
			class="flex-lg-column my-auto"
		>
			<ul
				class="nav nav-pills side-menu-nav justify-content-center"
				role="tablist"
			>
				<li
					class="nav-item"
					data-placement="top"
					title="Profile"
					v-b-tooltip.hover
				>
					<router-link
						to="profile"
						class="nav-link"
						id="pills-user-tab"
						data-toggle="pill"
						role="tab"
						:class="[currentRouteName === 'profile' ? 'active' : '']"
					>
						<i class="ri-user-2-line"></i>
					</router-link>
				</li>
				<li
					class="nav-item"
					data-placement="top"
					title="Chats"
					v-b-tooltip.hover
				>
					<router-link
						to="chat"
						class="nav-link"
						id="pills-user-tab"
						data-toggle="pill"
						role="tab"
						:class="[currentRouteName === 'chat' ? 'active' : '']"
					>
						<i class="ri-message-3-line"></i>
					</router-link>
				</li>
				<li
					v-if="isUserAdmin"
					class="nav-item"
					data-placement="top"
					title="Admin"
					v-b-tooltip.hover
				>
					<router-link
						to="admin"
						class="nav-link"
						id="pills-user-tab"
						data-toggle="pill"
						role="tab"
						:class="[currentRouteName === 'admin' ? 'active' : '']"
					>
						<i class="ri-admin-line"></i>
					</router-link>
				</li>
				<b-dropdown
					class="nav-item profile-user-dropdown d-inline-block d-lg-none"
					toggle-class="nav-link"
					variant="white"
					dropup
				>
					<template v-slot:button-content>
						<img v-if="userImage" :src="userImage" alt="" class="avatar-sm image-ava-single" />
						<b-avatar v-else class="avatar-sm"/>
					</template>
					<b-dropdown-item @click.prevent="tryToLogOut">
						Log out
						<i class="ri-logout-circle-r-line float-right text-muted"></i>
					</b-dropdown-item>
				</b-dropdown>
			</ul>
		</div>
		<!-- end side-menu nav -->

		<div class="flex-lg-column d-none d-lg-block">
			<ul class="nav side-menu-nav justify-content-center">
				<li class="nav-item">
					<div
						class="nav-link"
						id="light-dark"
						v-b-tooltip.hover title="Dark Mode"
						@click="toggleTheme"
					>
						<i class="ri-sun-line theme-mode-icon"></i>
					</div>
				</li>
				<b-dropdown
					class="nav-item btn-group dropup profile-user-dropdown"
					variant="white"
				>
					<template v-slot:button-content>
						<img v-if="userImage" :src="userImage" alt="" class="avatar-sm image-ava-single" />
						<b-avatar v-else class="avatar-sm"/>
					</template>
					<b-dropdown-item @click.prevent="tryToLogOut">
						Log out
						<i class="ri-logout-circle-r-line float-right text-muted"></i>
					</b-dropdown-item>
				</b-dropdown>
			</ul>
		</div>
		<!-- Side menu user -->
	</div>
</template>

<style scoped lang="scss">

</style>