<script>
import {profileMethods, profileComputed, notificationMethods,} from "@/state/helpers";
import {required, email} from "vuelidate/lib/validators";
import Loader from "@/components/Partials/Loader.vue";

/**
 * Profile component
 */
export default {
	data() {
		return {
			showModal: false,
			submitted: false,
			userPassword: "********",
			user: {
				name: "",
				email: "",
				password: "",
				imageFile: null,
				imagePreview: null,
				imageError: null,
			}
		};
	},
	components: {Loader},
	validations: {
		user: {
			name: {required},
			email: {required, email},
		}
	},
	computed: {
		...profileComputed,
		userName() {
			return this.userInfo.first_name;
		},
		userEmail() {
			return this.userInfo.email;
		},
		userImage() {
			return this.userInfo.image;
		},
		notification() {
			return this.$store ? this.$store.state.notification : null;
		},
		notificationAutoCloseDuration() {
			return this.$store && this.$store.state.notification ? 5 : 0;
		},
	},
	methods: {
		...profileMethods,
		...notificationMethods,
		openModal() {
			this.showModal = !this.showModal;
			this.user.name = this.userName;
			this.user.email = this.userEmail;
		},
		handleImageUpload(event) {
			const file = event.target.files[0];
			const validTypes = ["image/jpeg", "image/png", "image/jpg", "image/gif"];

			// Reset previous errors and file preview
			this.imageError = null;
			this.imagePreview = null;
			this.imageFile = null;

			if (file) {
				// Validate file type
				if (!validTypes.includes(file.type)) {
					this.imageError = "Invalid file type. Only JPEG, PNG, JPG, and GIF are allowed.";
					return;
				}

				// Store the file
				this.imageFile = file;

				// Preview the image
				this.imagePreview = URL.createObjectURL(file);
			}
		},
		updateProfile() {
			this.submitted = true;
			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			} else {
				const formData = new FormData();
				formData.append("name", this.user.name);
				formData.append("email", this.user.email);
				formData.append("password", this.user.password);

				if(this.user.password !== '') {
					formData.password = this.user.password
				}

				if (this.imageFile) {
					formData.append("image", this.imageFile);
				}

				this.updateUserInfo(formData)
			}
			this.submitted = false;
		},
	},
	mounted() {
		this.$store.dispatch("profile/getUserInfo");
	}
};
</script>
<template>
	<div>
		<h4 class="px-4 pt-4 mb-0">{{ $t("chat.tabs.profile.title") }}</h4>
		<Loader v-if="loading"/>
		<div v-else>
			<div class="text-center p-4 border-bottom">
				<div class="mb-4 profile-user">
					<img v-if="userImage" :src="userImage" class="avatar-md image-ava-single" alt="">
					<b-avatar v-else class="avatar-md"/>
				</div>
				<b-modal
					v-model="showModal"
					:title="$t('chat.tabs.settings.modal.title')"
					hide-footer
				>
					<form @submit.prevent="updateProfile">
						<b-alert
							:variant="notification.type"
							class="mt-3"
							v-if="notification.message"
							:show="notificationAutoCloseDuration"
							dismissible
						>{{ notification.message }}
						</b-alert>
						<div class="form-group">
							<label for="imageUpload">Image</label>
							<input
								type="file"
								id="imageUpload"
								class="form-control"
								@change="handleImageUpload"
								accept="image/jpeg, image/png, image/jpg, image/gif"
							/>
							<div v-if="user.imagePreview" class="image-preview mt-3">
								<img :src="user.imagePreview" alt="Image Preview" class="img-thumbnail" />
							</div>
							<div v-if="user.imageError" class="invalid-feedback">{{ user.imageError }}</div>
						</div>
						<div class="form-group mb-4">
							<label for="updateName-input">{{
									$t("chat.tabs.settings.modal.form.name.label")
								}}</label>
							<input
								type="text"
								class="form-control"
								id="updateName-input"
								:placeholder=" $t('chat.tabs.settings.modal.form.name.placeholder')"
								v-model="user.name"
								minlength="3"
								required
								:class="{
                          'is-invalid': submitted && $v.user.name.$error,
                        }"
							/>
							<div
								v-if="submitted && $v.user.name.$error"
								class="invalid-feedback"
							>
                    <span v-if="!$v.user.name.required">{{
							$t("chat.tabs.settings.modal.form.name.validation.required")
						}}</span>
							</div>
						</div>
						<div class="form-group">
							<label>{{ $t('register.form.email.label') }}</label>
							<div class="input-group mb-3 bg-soft-light input-group-lg rounded-lg">
								<div class="input-group-prepend">
								<span class="input-group-text border-light text-muted">
									<i class="ri-mail-line"></i>
								</span>
								</div>
								<input
									v-model="user.email"
									type="email"
									class="form-control bg-soft-light border-light"
									:placeholder="$t('register.form.email.placeholder')"
									:class="{ 'is-invalid': submitted && $v.user.email.$error }"
									disabled
								/>
								<div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
								<span
									v-if="!$v.user.email.required"
								>{{ $t('register.form.email.validation.required') }}</span>
									<span
										v-if="!$v.user.email.email"
									>{{ $t('register.form.email.validation.invalid') }}</span>
								</div>
							</div>
						</div>
						<div class="form-group">
							<label>{{ $t('register.form.password.label') }}</label>
							<div class="input-group mb-3 bg-soft-light input-group-lg rounded-lg">
								<div class="input-group-prepend">
								<span class="input-group-text border-light text-muted">
									<i class="ri-lock-line"></i>
								</span>
								</div>
								<input
									v-model="user.password"
									type="password"
									class="form-control bg-soft-light border-light"
									:placeholder="$t('register.form.password.placeholder')"
									:class="{ 'is-invalid': submitted && $v.user.password.$error }"
								/>
								<div v-if="submitted && $v.user.password.$error" class="invalid-feedback">
								<span
									v-if="!$v.user.password.required"
								>{{ $t('register.form.password.validation.required') }}</span>
									<span
										v-if="!$v.user.password.email"
									>{{ $t('register.form.password.validation.invalid') }}</span>
								</div>
							</div>
						</div>
						<div class="text-right pt-5 mt-3">
							<b-button variant="link" @click="showModal = false">{{
									$t("chat.tabs.settings.modal.form.button.close")
								}}
							</b-button>
							<b-button type="submit" variant="primary" class="ml-1">{{
									$t("chat.tabs.settings.modal.form.button.save")
								}}
							</b-button>
						</div>
					</form>
				</b-modal>
				<h5 class="font-size-16 mb-1 text-truncate">
					{{ userName }}
				</h5>
				<p class="text-muted text-truncate mb-1">
					<i
						class="ri-record-circle-fill font-size-10 text-success mr-1 d-inline-block"
					></i>
					{{ $t("chat.tabs.profile.status") }}
				</p>
			</div>
			<!-- End profile user -->

			<!-- Start user-profile-desc -->
			<div class="p-4 user-profile-desc">
				<b-card no-body class="border custom-accordion">
					<b-card-header class="d-flex align-items-center justify-content-between">
							<h5 class="font-size-15 m-0">
								<i class="ri-user-2-line mr-2 align-middle d-inline-block"></i>
								{{ $t("chat.tabs.profile.about.text") }}
							</h5>
						<button
							type="button"
							style="max-width: 200px;"
							class="btn btn-primary"
							@click="openModal"
						>
							<i class="ri-edit-fill mr-1 align-middle"></i>
							{{ $t("chat.tabs.settings.info.edit") }}
						</button>
					</b-card-header>
					<b-card-body>
						<div>
							<p class="text-muted mb-1">
								{{ $t("chat.tabs.profile.about.name.text") }}
							</p>
							<h5 class="font-size-14">
								{{ userName }}
							</h5>
						</div>

						<div class="mt-4">
							<p class="text-muted mb-1">
								{{ $t("chat.tabs.profile.about.email.text") }}
							</p>
							<h5 class="font-size-14">
								{{ userEmail }}
							</h5>
						</div>

						<div class="mt-4">
							<p class="text-muted mb-1">
								Password
							</p>
							<h5 class="font-size-14 mb-0">
								{{ userPassword }}
							</h5>
						</div>
					</b-card-body>
				</b-card>
				<!-- end profile-user-accordion -->
			</div>
			<!-- end user-profile-desc -->
		</div>
	</div>
</template>
<style scoped>
.image-preview img {
	max-width: 100%;
	max-height: 200px;
	display: block;
}
</style>